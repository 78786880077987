// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-display: auto;
//   font-weight: 400;
//   src: url("../fonts/Inter-Regular.woff2") format("woff2"),
//        url("../fonts/Inter-Regular.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-display: auto;
//   font-weight: 400;
//   src: url("../fonts/Inter-Italic.woff2") format("woff2"),
//        url("../fonts/Inter-Italic.woff") format("woff");
// }

// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-display: auto;
//   font-weight: 600;
//   src: url("../fonts/Inter-Medium.woff2") format("woff2"),
//        url("../fonts/Inter-Medium.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-display: auto;
//   font-weight: 600;
//   src: url("../fonts/Inter-MediumItalic.woff2") format("woff2"),
//        url("../fonts/Inter-MediumItalic.woff") format("woff");
// }

// @font-face {
//   font-family: 'Inter';
//   font-style:  normal;
//   font-display: auto;
//   font-weight: 800;
//   src: url("../fonts/Inter-Bold.woff2") format("woff2"),
//        url("../fonts/Inter-Bold.woff") format("woff");
// }
// @font-face {
//   font-family: 'Inter';
//   font-style:  italic;
//   font-display: auto;
//   font-weight: 800;
//   src: url("../fonts/Inter-BoldItalic.woff2") format("woff2"),
//        url("../fonts/Inter-BoldItalic.woff") format("woff");
// }
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;600;700&family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
